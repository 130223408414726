'use client';
import { useContext, useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { AppContext, PageType } from 'context/AppContext';
import { useQueryParam } from 'customHooks/useQueryParam';
import { isMobile } from 'utils/detect';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import { AdAllianceLib, SiteType } from './adAllianceLib';

export const AdAlliance = () => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  const {
    pageType,
    pageMetaData: { adZone, keywords },
  } = useContext(AppContext);

  const pathname = usePathname();
  const device = useQueryParam('device');
  const currentAdZone = pathname === '/' ? 'homepage' : adZone;

  const isGujAdPreview = !!useQueryParam('gujAd');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [isMoltenTopScriptLoaded, setIsMoltenTopScriptLoaded] = useState(false);

  const MOLTEN_SCRIPT_TOP = isRefresh ? 'https://www.rtl.de/mbundle/top_v2.js' : 'https://www.rtl.de/mbundle/top.js';
  const MOLTEN_SCRIPT_BOTTOM = isRefresh
    ? 'https://www.rtl.de/mbundle/bottom_v2.js'
    : 'https://www.rtl.de/mbundle/bottom.js';

  const MOLTEN_SCRIPT_PREVIEW_TOP = isRefresh
    ? 'https://adctrl.emsmobile.de/molten/preview/rtl.de/MoltenBundle.js'
    : 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.top.js';
  const MOLTEN_SCRIPT_PREVIEW_BOTTOM = isRefresh
    ? 'https://adctrl.emsmobile.de/molten/preview/rtl.de/MoltenBundle.bottom.js'
    : 'https://adctrl.emsmobile.de/molten/preview/neu.rtl.de/MoltenBundle.bottom.js';

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    const getSiteType = () =>
      ({ android: SiteType.Android, ios: SiteType.iOS })[device?.toLowerCase() ?? ''] ??
      (isMobile() ? SiteType.Mobile : SiteType.Desktop);

    AdAllianceLib.initialize({
      siteType: getSiteType(),
      adZone: currentAdZone,
      keywords: keywords,
      pageType: pageType === 'article' ? PageType.Article : PageType.Overview,
    });
  }, [device, keywords, pageType, currentAdZone]);

  if (pageLoaded) {
    return (
      <>
        <Script
          id="molten-top"
          src={isGujAdPreview ? MOLTEN_SCRIPT_PREVIEW_TOP : MOLTEN_SCRIPT_TOP}
          onLoad={() => setIsMoltenTopScriptLoaded(true)}
          async
        />

        {isMoltenTopScriptLoaded && (
          <Script
            id="molten-bottom"
            strategy="afterInteractive"
            src={isGujAdPreview ? MOLTEN_SCRIPT_PREVIEW_BOTTOM : MOLTEN_SCRIPT_BOTTOM}
            async
          />
        )}
      </>
    );
  }

  return null;
};
