'use client';
import parse from 'html-react-parser';
import Partials from 'components/partials/Partials';
import type { WidgetMenuProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { useNoNav } from 'customHooks/useNoNav';
import Link from '../Link/Link';
import { Typography } from '../Typography';
import ScrollTopButton from './components/ScrollTopButton';
import styles from './FooterNew.module.scss';

const FooterNew = ({
  context,
  seoText,
  seoTitle,
  pageType,
}: {
  context?: Array<WidgetMenuProps>;
  seoTitle?: string;
  seoText?: string;
  pageType?: string;
}) => {
  const noNav = useNoNav();

  if (noNav) return <></>;

  return (
    <footer className={styles.footer} data-testid="footer">
      <aside />
      <div className={styles.mainContentZone}>
        <Typography className={styles.inner} as="div" variant="4xs">
          {pageType !== 'article' && seoTitle && seoText && (
            <div className={styles.text}>
              {seoTitle && (
                <Typography as="h2" variant="3xs">
                  {seoTitle}
                </Typography>
              )}

              {seoText && <div>{parse(seoText)}</div>}
            </div>
          )}

          {context && context.length > 0 && <Partials context={context[0]} />}

          <div className={styles.homeLink}>
            <Link href="/">Home</Link>
            <ScrollTopButton />
          </div>

          <div>© {new Date().getFullYear()} RTL interactive GmbH</div>
        </Typography>
      </div>
      <aside />
    </footer>
  );
};

export default FooterNew;
