'use client';
import { useContext } from 'react';
import { Script } from 'components/UI/Script';
import { AppContext } from 'context/AppContext';

export const AdDefend = ({ script }: { script: string }) => {
  const {
    pageMetaData: { noAds },
  } = useContext(AppContext);
  if (noAds || script === '') {
    return null;
  }

  return <Script script={script} />;
};
