'use client';
import { useContext } from 'react';
import parse from 'html-react-parser';
import Partials from 'components/partials/Partials';
import type { WidgetMenuProps } from 'components/partials/widget/widget-menu/widget-menu-default';
import { AppContext } from 'context/AppContext';
import { useNoNav } from 'customHooks/useNoNav';
import { isFeatureFlagEnabled } from 'utils/featureFlags';
import Link from '../Link/Link';
import { Logo } from '../Logo';
import { Typography } from '../Typography';
import ScrollTopButton from './components/ScrollTopButton';
import styles from './Footer.module.scss';
import FooterNew from './FooterNew';

const Footer = ({
  context,
  seoText,
  seoTitle,
  pageType,
}: {
  context?: Array<WidgetMenuProps>;
  seoTitle?: string;
  seoText?: string;
  pageType?: string;
}) => {
  const { featureFlags } = useContext(AppContext);
  const isRefresh = isFeatureFlagEnabled('ft_temp_enable_global_refresh', featureFlags);

  const noNav = useNoNav();

  if (noNav) return <></>;

  if (isRefresh) return <FooterNew context={context} seoText={seoText} seoTitle={seoTitle} pageType={pageType} />;

  return (
    <footer className={styles.footer} data-testid="footer">
      <Typography className={styles.inner} as="div" variant="4xs">
        {!isRefresh && <Logo size="small" className={styles.logo} />}

        {pageType !== 'article' && seoTitle && seoText && (
          <div className={styles.text}>
            {seoTitle && (
              <Typography as="h2" variant="3xs">
                {seoTitle}
              </Typography>
            )}

            {seoText && <div>{parse(seoText)}</div>}
          </div>
        )}

        {context && context.length > 0 && <Partials context={context[0]} />}

        <div className={styles.homeLink}>
          <Link href="/">Home</Link>
          <ScrollTopButton />
        </div>

        <div>© {new Date().getFullYear()} RTL interactive GmbH</div>
      </Typography>
    </footer>
  );
};

export default Footer;
